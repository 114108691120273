// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import "bootstrap";
import "@popperjs/core";
import "@fortawesome/fontawesome-free/js/all";
import "flatpickr";
import "../stylesheets/application";

import Rails from "@rails/ujs";
//import Turbolinks from "turbolinks"
//import * as ActiveStorage from "@rails/activestorage"
import "channels";

Rails.start();
//Turbolinks.start()
//ActiveStorage.start()

/*
 * Enterキー押下で次の要素にフォーカス移動
 * formタグ使用してる場合のみ
 * フォーカスしたくない要素には aria-hidden="true" 追加
 * input type="hidden" もスキップ
 */
function enterNext(event) {
  if (event.key == "Enter") {
    const form = event.target.form;
    const index = Array.prototype.indexOf.call(form, event.target);
    for (let i = 1; i < 100; i++) {
      const nextIndex = index + i;
      const nextElement = form.elements[nextIndex];
      if (!nextElement) continue;
      const aria_hidden = nextElement.getAttribute("aria-hidden");
      const type_hidden = nextElement.type == "hidden";
      const disabled = nextElement.getAttribute("disabled");
      if (aria_hidden || type_hidden || disabled == "disabled") {
        continue;
      }
      if (nextElement) {
        nextElement.focus();
        break;
      }
    }
    event.preventDefault();
  }
}

document.addEventListener("DOMContentLoaded", () => {
  const elements = document.getElementsByClassName("enter-next");
  for (let el of elements) {
    el.addEventListener("keydown", enterNext);
  }

  const tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  );
  const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });

  const toastElList = [].slice.call(document.querySelectorAll(".toast"));
  const toastList = toastElList.map(function (toastEl) {
    return new bootstrap.Toast(toastEl);
  });

  const popoverTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="popover"]')
  );
  const popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl);
  });
});

function previewImage(selector) {
  const target = this.event.target;
  const file = target.files[0];
  const reader = new FileReader();
  reader.onloadend = function () {
    const preview = document.querySelector(selector);
    if (preview) {
      preview.src = reader.result;
    }
  };
  if (file) {
    reader.readAsDataURL(file);
  }
}
